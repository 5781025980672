// @flow
import * as React from 'react'
// import cn from 'classnames'
import { Store } from 'Store'
import ShoppingCard from 'components/ShoppingCard'

import withTheme from 'hoc/withTheme'

import supportedThemes from './themes/__supportedThemes.js'
import styles from './styles.module.scss'

const ShoppingSidebar = ({ handleOnSelection, theme }) => {
    const { state } = React.useContext(Store)


    const model = state.campaign.models.find(
        m =>
        m.make === state.lead.fields.newVehicleMake &&
        m.name === state.lead.fields.newVehicleModel &&
        m.year === state.lead.fields.newVehicleModelYear
    )

    const color = state.lead.fields.newVehicleModelColor

    return (
            <div className={styles.sidebar} style={{
                background: theme.background
            }}>
                <ShoppingCard
                    isVisible={true}
                    buttonAction={'text'}
                    buttonText={'Change selection'}
                    onClick={handleOnSelection}
                    xl
                    colorProp={color}
                    make={model.make}
                    name={model.name}
                    year={model.year}
                    image={model.images.front_34.m}
                    voucherValue={
                    state.campaign.incentives.voucher.type === 'variable'
                        ? model.voucherValue
                        : state.campaign.incentives.voucher.value
                    }
                    startingPrice={model.startingPrice}
                    customPrimaryColor={theme.customPrimaryColor}
                    customSecondaryColor={theme.customSecondaryColor}
                />
            </div>
    )
}

export default withTheme(supportedThemes)(ShoppingSidebar)
